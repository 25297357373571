import React from 'react'
import './about.css'
import ME from '../../assets/about-me.jpg'
import {FaAward} from 'react-icons/fa'
import {BiCodeAlt} from 'react-icons/bi'

const About = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Me Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>3+ years Industry Working</small>
            </article>
            {/* <article className='about__card'>
              <BiCodeAlt className='about__icon'/>
              <h5>Projects</h5>
              <small>10+ Completed</small>
            </article> */}
          </div>
          <p>An IT expert passionate about software development. Experienced in working with teams in different software stages, ensuring a high-quality and fulfillment of business market needs.</p>
          <p>My goal is to contribute something new to the world with technology which have an emotional value and which is purely focused on the betterment of the society. I also want to inspire other youngsters the same way i was motivated by the rise of technology in the past decade.</p>
          <p>I am currently working as a Developer in Huawei Technologies India Pvt. Ltd, Bangalore.</p>

        <a href="#contact" className='btn btn-primary'>Lets Talk?</a>
        </div>
      </div>

    </section>
  )
}

export default About