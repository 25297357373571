import React from 'react'
import './contact.css'
import { AiOutlineMail, AiOutlineLinkedin, AiOutlineWhatsApp } from 'react-icons/ai'
import { useRef } from 'react';
import emailjs from 'emailjs-com';
import swal from 'sweetalert';

const Contact = () => {
  const form = useRef();

  function JSalert(value){
    swal(value);
  }

  const sendEmail = (e) => {

    e.preventDefault();

    emailjs.sendForm('service_8qye6hn', 'template_oakusvi', form.current, 'UVnmhDDv1Z8oEPwqo')
      .then((result) => {
        JSalert({
          title: "Success!",
          text: "Message Delivered Successfully!",
          icon: "success",
        });
      }, (error) => {
        JSalert({
          title: "Error!",
          text: "Message was not delivered, please contact me through other channels!",
          icon: "error",
        });
      });
      e.target.reset();
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <AiOutlineMail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>saneshcm@gmail.com</h5>
            <a href="mailto:itzme.sanesh@gmail.com" target="_blank">Send a Message</a>
          </article>
          <article className='contact__option'>
            <AiOutlineLinkedin className='contact__option-icon' />
            <h4>Linkedin</h4>
            <h5>Sanesh C M</h5>
            <a href="https://www.linkedin.com/in/sanesh-cm/" target="_blank">Send a Message</a>
          </article>
          <article className='contact__option'>
            <AiOutlineWhatsApp className='contact__option-icon' />
            <h4>WhatsApp</h4>
            <h5>+91-9745609556</h5>
            <a href="https://api.whatsapp.com/send?phone=+919745609556" target="_blank">Send a Message</a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS*/}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email Address' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type="submit" className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact