import React from 'react'
import './footer.css'
import {BsLinkedin, BsGithub, BsInstagram} from 'react-icons/bs'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Sanesh C M</a>

      <ul className='footlinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
      <a href="https://www.linkedin.com/in/sanesh-cm/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/SaneshCM" target="_blank"><BsGithub/></a>
        <a href="https://instagram.com/nomadicwacko" target="_blank"><BsInstagram/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Sanesh Online. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer